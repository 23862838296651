import './styles/App.scss';
import Main from './components/MainComponent';
import ScrollToTop from './components/ScrollToTop';
import {BrowserRouter} from "react-router-dom";

function App() {
  return (
    <div className="App">
    	<BrowserRouter>
    		<ScrollToTop />
    	    <Main />	
    	</BrowserRouter>
    </div>
  );
}

export default App;
