import React 	from 'react'; 
import Header 	from './HeaderComponent';
import Home 	from './HomeComponent';
import Projects from './ProjectsComponent';
import About 	from './AboutComponent';
import Contact 	from './ContactComponent';
import Footer 	from './FooterComponent';
import {Switch, Route, Redirect} from "react-router-dom";


function Main(){

	return(
		<React.Fragment>
			<Header/> 
			<main>
				<Switch>
					<Route exact path="/"><Home /></Route>
					<Route path="/projects"><Projects /></Route>
					<Route path="/about"><About /></Route>
					<Route path="/contact"><Contact /></Route>
					<Redirect to='/' />
				</Switch>
			</main>
			<Footer/> 
		</React.Fragment>
	)
}

 export default Main; 