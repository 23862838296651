import '../styles/AboutComponent.scss';
import React			from 'react'; 
import {ProgressBar} 	from 'react-bootstrap';
import { ABOUT_DATA }	from '../shared/about';
import activities1Xs from '../assets/images/activities1-xs.JPG';
import activities2Xs from '../assets/images/activities2-xs.JPG';
import activities3Xs from '../assets/images/activities3-xs.JPG';
import activities1		from '../assets/images/activities1.JPG';
import activities2		from '../assets/images/activities2.JPG';
import activities3		from '../assets/images/activities3.JPG';

function About() {


	const AboutData = (props) => {

		const type = props.type;

		const aboutData = ABOUT_DATA.map(data => {
			if (type === data.type) {
				return (
					<div key={data.id} className='about-details d-flex flex-wrap'>
						<div className="col-12 col-sm-10">
							<p>{data.title}</p>
							<p>{data.institution}</p>
						</div>
						<div className="col-12 col-sm-2 order-sm-first date-place">
							<p>{data.date}</p>
							<p>{data.place}</p>
						</div>
						<div className="col-12 col-sm-2">
						</div>
						<div className="col-12 col-sm-10 about-description">
							<p>{data.description}</p>
						</div>
					</div>
				)
			}

			return null;
		}); 

		return(
			<div id={type} className ='container about-category' >
				<h4>{type}</h4>
				{aboutData}
			</div>
		)
	};


	const LanguageBlock = () => {

		const type = 'languages';

		const languageData = ABOUT_DATA.map(data => {
			if (data.type === 'language') {
				return (
					<div key={data.id} className="d-flex bar">
						<div className = 'col-4 col-sm-2 bar-label'>{data.title}</div>  
						<div className = 'col-8 col-sm-4 bar-div'>  
							<ProgressBar now={data.value} />
						</div>
					</div>
				)
			}

			return null; 
		}); 

		return(
			<div id={type} className ='container about-category' >
				<h4>{type}</h4>
				<div>
					{languageData}
				</div>
			</div>
		)
	}


	return(

	    <div className='page-container' id='about-page'>

	    	<div className='page-header d-flex flex-wrap'>
	    		<div className='col-12 col-md-3 page-title'>
	    			<h3>About</h3>
	    		</div>
	    		<div className='col-12 col-md-9 col-xl-5 page-description'>
	    			<p>A bit more about my background and experience</p>
	    			<p>
	    				After graduating with a double degree in Aerospace Engineering, I have relocated to Indonesia and India where I earnt four years of rich and varied 
	    				experiences in project/management roles. 
					</p>
	    		</div>
	    	</div>

			<div className='page-content'>
		    	
	    		<AboutData type='experience'/>
	    		<AboutData type='education'/>
	    		<LanguageBlock />

		    	<div id="interest" className ='about-category' >
		    		<div className='container'>
						<h4>When I don't work...</h4>
			    		<div className='d-flex flex-wrap'>
							<div className="col-12">
								<p>
									I travel, I explore, I learn.<br />I have been enjoying the exotic countries 
									I have been living in over the past few years:<br />always outdoors for a hike, a run 
									or a dive or discovering the local culture and learning a new language!
								</p>
							</div>
							<div className="col-4 text-center hobby-pic-div">
								<picture>
									<source media="(max-width: 575.98px)" srcset={activities1Xs} />
									<source media="(min-width: 576px)" srcset={activities1} />
									<img alt="hobby pictures" src={activities1} className="img-fluid" id="activities1" />
								</picture>
							</div>
							<div className="col-4 text-center hobby-pic-div">
								<picture>
									<source media="(max-width: 575.98px)" srcset={activities2Xs} />
									<source media="(min-width: 576px)" srcset={activities2} />
									<img alt="hobby pictures" src={activities2} className="img-fluid" id="activities2" />
								</picture>
							</div>
							<div className="col-4 text-center hobby-pic-div">
								<picture>
									<source media="(max-width: 575.98px)" srcset={activities3Xs} />
									<source media="(min-width: 576px)" srcset={activities3} />
									<img alt="hobby pictures" src={activities3} className="img-fluid" id="activities3" />
								</picture>
							</div>
						</div>
					</div>
				</div>
		    </div>
		</div>
	);
}
 export default About; 
