import randomQuoteMachine 	from '../assets/images/projects/randomQuoteMachine.jpg'; 
import pomodoroTimer 		from '../assets/images/projects/pomodoroClock.jpg'; 
import drumMachine 			from '../assets/images/projects/drumMachine.jpg' ; 
import calculator			from '../assets/images/projects/calculator.jpg'; 
import markdownPreviewer 	from '../assets/images/projects/markdownPreviewer.jpg' ;
import heatMap				from '../assets/images/projects/heatMap.jpg';  
import choropleth 			from '../assets/images/projects/choropleth.jpg'; 
import treemap 				from '../assets/images/projects/treemap.jpg'; 
import issueTracker			from '../assets/images/projects/issueTracker.jpg'; 
import library 				from '../assets/images/projects/library.jpg'; 
import sudokuSolver 		from '../assets/images/projects/sudokuSolver.jpg'; 
import translator 			from '../assets/images/projects/translator.jpg'; 
import stockPriceChecker	from '../assets/images/projects/stockPriceChecker.jpg'; 
import anonymousMessageBoard from '../assets/images/projects/anonymousMessageBoard.jpg'; 


export const PROJECTS_DATA = [
	{
		id: 		1,
		title: 		'Random Quote Machine', 
		subtitle: 	'React',
		img: 		randomQuoteMachine, 
		link: 		'https://lysianel.github.io/FCC3-RandomQuoteMachine/index.html'
	},
	{
		id: 		2,
		title: 		'Pomodoro Timer', 
		subtitle: 	'React',
		img: 		pomodoroTimer, 
		link: 		'https://lysianel.github.io/FCC3-25-5Clock'
	},
	{
		id: 		3,
		title: 		'Drum Machine', 
		subtitle: 	'React',
		img: 		drumMachine, 
		link: 		'https://lysianel.github.io/FCC3-BuildDrum/index.html'
	},
	{
		id: 		4,
		title: 		'Calculator', 
		subtitle: 	'React',
		img: 		calculator, 
		link: 		'https://lysianel.github.io/FCC3-JavaScriptCalculator'
	},
	{
		id: 		5,
		title: 		'Markdown Previewer', 
		subtitle: 	'React',
		img: 		markdownPreviewer, 
		link: 		'https://lysianel.github.io/FCC3-MarkdownPreviewer'
	},
	{
		id: 		6,
		title: 		'Heat Map', 
		subtitle: 	'd3.js',
		img: 		heatMap, 
		link: 		'https://lysianel.github.io/FCC4-d3HeatMap'
	},
		{
		id: 		7,
		title: 		'Choropleth', 
		subtitle: 	'd3.js',
		img: 		choropleth, 
		link: 		'https://lysianel.github.io/FCC4-d3Choropleth'
	},

		{
		id: 		8,
		title: 		'Treemap', 
		subtitle: 	'd3.js',
		img: 		treemap, 
		link: 		'https://lysianel.github.io/FCC4-d3TreeMap'
	},
		{
		id: 		9,
		title: 		'Issue Tracker', 
		subtitle: 	'API / Express.js / Mongoose',
		img: 		issueTracker, 
		link: 		'https://everlastingjuicystrings--five-nine.repl.co/'
	},
		{
		id: 		10,
		title: 		'Personal Library', 
		subtitle: 	'API / Express.js / Mongoose',
		img: 		library, 
		link: 		'https://rosybrownsufficientcybernetics--five-nine.repl.co/'
	},
		{
		id: 		11,
		title: 		'Sudoku Solver', 
		subtitle: 	'API / Express.js / Algorithm',
		img: 		sudokuSolver, 
		link: 		'https://breakableinvolvedsection--five-nine.repl.co/'
	},
		{
		id: 		12,
		title: 		'American-British Translator', 
		subtitle: 	'API / Express.js / Regex',
		img: 		translator, 
		link: 		'https://emptydarksalmondiscussions--five-nine.repl.co/'
	},
		{
		id: 		13,
		title: 		'Stock Price Checker', 
		subtitle: 	'API / Express.js / Mongoose',
		img: 		stockPriceChecker, 
		link: 		'https://fcc-stockpricechecker.lysiane.repl.co/'
	},
		{
		id: 		14,
		title: 		'Anonymous Message Board', 
		subtitle: 	'API / Express.js / Mongoose',
		img: 		anonymousMessageBoard, 
		link: 		'https://fcc-project-messageboard.lysiane.repl.co/'
	},

];