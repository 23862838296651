import '../styles/HeaderComponent.scss';
import React, { useState }  from 'react'; 
import {Navbar, Nav} from 'react-bootstrap';
import {NavLink}     from "react-router-dom";


function Header() {

    const [expanded, setExpanded] = useState(false);

    return (
        <header>
            <Navbar expand='sm' fixed='top' className="navbar-dark" expanded={expanded}>
                <NavLink id="home" to="/" >Home</NavLink>
                <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto" onClick={() => setExpanded(false)}>
                        <NavLink to="/projects">Projects</NavLink>
                        <NavLink to="/about">About</NavLink>
                        <NavLink to="/contact">Contact</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
}

//function Header() {

//    return (
//        <header>
//            <Navbar expand='sm' fixed='top' className="navbar-dark">
//                <NavLink id="home" to="/" >Home</NavLink>
//                <Navbar.Toggle aria-controls="basic-navbar-nav" />
//                <Navbar.Collapse id="basic-navbar-nav">
//                    <Nav className="ml-auto">
//                        <NavLink to="/projects">Projects</NavLink>
//                        <NavLink to="/about">About</NavLink>
//                        <NavLink to="/contact">Contact</NavLink>
//                    </Nav>
//                </Navbar.Collapse>
//            </Navbar>
//        </header>
//    );
//}

export default Header; 