import '../styles/HomeComponent.scss';
import React 							from 'react';
import {FontAwesomeIcon} 				from '@fortawesome/react-fontawesome';
import {faCode, faInfo, faPaperPlane} 	from '@fortawesome/free-solid-svg-icons';
import {Link} 							from 'react-router-dom';
import imgProfile 						from '../assets/images/profile.jpg'; 

function Home() {

	return(
		<div id="homepage">
		   	<div id="homesection" >
		   		<div className="container">

				  <div id="title">
				  	<div>
				  		<h1>Lysiane Lesaffre</h1>
				  		<h2 id="subtitle">Engineer | <span id="webdev">Web Developer</span></h2>
				  	</div>
				  </div>

				  <div id="image-profile">
				  	<img src={imgProfile} alt="profile"/>
				  </div>

				  <div id="introduction">
				  	<p>Hello and Welcome!</p>
				  	<p>My name is Lysiane and as I got started with web development a few months back, I have become more
				  	 and more eager to learn and discover this huge and exciting world. With more practice and projects
				  	  coming up, I have put this first site together with my most recent learnings and projects. Hope you enjoy it! </p>
				  </div>
				</div>
		    </div>

			<div className="container d-flex flex-wrap" id="menu">
			  	<div className="col-12 col-md-4">
			  		<Link to='/projects'>
			  			<FontAwesomeIcon className="icon" icon={faCode}/>
			  			Web Projects
			  		</Link>
			  	</div>
			  	<div className="col-12 col-md-4">
			  		<Link to='/about'>
			  			<FontAwesomeIcon className="icon" icon={faInfo}/>
			  			About
			  		</Link>
			  	</div>
			  	<div className="col-12 col-md-4">
			  		<Link to='/contact'>
			  			<FontAwesomeIcon className="icon" icon={faPaperPlane}/>
			  			Contact
			  		</Link>
			  	</div>
			</div>
		</div>
    )
}

 export default Home; 
