import { faDatabase } 		from '@fortawesome/free-solid-svg-icons';
import {faHtml5, faCss3Alt, faJs, faReact, faNode } 	from '@fortawesome/free-brands-svg-icons';

export const TECH_DATA = [
	{
		id: 	1,
		name: 	'HTML',
		faName: faHtml5
	},
	{
		id: 	2,
		name: 	'CSS',
		faName: faCss3Alt
	},
	{
		id: 	3,
		name: 	'JavaScript',
		faName: faJs
	},
	{
		id: 	4,
		name: 	'React',
		faName: faReact
	},
	{
		id: 	5,
		name: 	'Node.js',
		faName: faNode
	},
	{
		id: 	6,
		name: 	'MongoDB',
		faName: faDatabase
	}
];