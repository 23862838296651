import '../styles/FooterComponent.scss';
import React 				from 'react'; 
import {Link} 				from 'react-router-dom';
import { FontAwesomeIcon } 	from '@fortawesome/react-fontawesome';
import {faLinkedinIn} 		from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} 		from '@fortawesome/free-solid-svg-icons';

function Footer() {

	return(
	    <div className="footer">
			<div>
				<div className="row align-items-center text-center">
					<div className="col-12 col-md-2">			
						<a 
							href="mailto:lysiane.lesaffre@outlook.com"
							target="_blank"
							rel="noopener noreferrer"
							className="contact-link">
							<FontAwesomeIcon className="icon" icon={faEnvelope} />
						</a> 
						<a 
							href="https://www.linkedin.com/in/lysianelesaffre/"
							target="_blank"
							rel="noopener noreferrer"
							className="contact-link">
							<FontAwesomeIcon className="icon" icon={faLinkedinIn} />
						</a> 
					</div>

					<div className="col-12 col-md-5 order-md-first">
						<ul className="list-unstyled inline d-flex flex-wrap">
							<li><Link to="/home">Home</Link></li>
							<li><Link to="/projects">Projects</Link></li>
			     			<li><Link to="/about">About</Link></li>
			     			<li><Link to="/contact">Contact</Link></li>
			     		</ul>
					</div>
		      		
					<div className="col-12 col-md-5" id="reference">
						© Lysiane Lesaffre - December 2020				
					</div>
				</div>
			</div>
	    </div>
	);
}

 export default Footer; 
