export const ABOUT_DATA = [
	{
		id: 			1, 		
		type: 			'experience', 
		title: 			'Project Manager', 
		institution: 	'Ubisoft India Studios', 
		description: 	'At Ubisoft Mumbai and Pune, I am working closely with the Production and Human Resources teams ' + 
						'to design, build and deploy training initiatives for Indian studios within programming and game art specializations. Also, I have led ' + 
						'the e-onboarding project to welcome our new joiners remotely and have deployed new tools to support this process.', 
		date: 			'From Oct. 2019',
		place: 			'Mumbai, India'
	}, 

	{
		id: 			2, 	
		type: 			'experience', 
		title: 			'Project Manager & Executive Assistant', 
		institution: 	'Airbus Indonesia', 
		description: 	'Over these 3 years at Airbus Indonesia, I have supported the Head of Country in various management tasks and transverse projects. ' + 
						'With a team of 50+ people in Indonesia, this involved extensive coordination with all teams in the country, frequent interactions with ' + 
						'Asia regional team and European headquarters.\nAlso, I have supported the legal restructuring of the company ' + 
						'and have led the complete relocation of our offices to a co-working space - a first at Airbus.',
		date: 			'2016 - 2019',
		place: 			'Jakarta, Indonesia'
	}, 

	{
		id: 			3, 	
		type: 			'education', 
		title: 			'Master of Science in Aerospace Engineering', 
		institution: 	'Technical University of Munich', 
		description: 	'Focus: Helicopter Technology, Lightweight Structures, Composite Materials\n' + 
						'Master’s thesis: Development of a structural optimization method for composite rotor blades with placement of natural frequencies (Matlab)\n' + 
						'Term paper: Sensor instrumentation of a composite rotor blade for flapwise, lagwise and torsional load measurements (Matlab)',
		date: 			'2014 - 2016',
		place: 			'Munich, Germany'
	},

	{
		id: 			4, 	
		type: 			'education', 
		title: 			'Engineering Master’s Degree', 
		institution: 	'Ecole Centrale de Nantes', 
		description: 	'2-year multi-disciplinary engineering training\nTreasurer of the Junior Enterprise Centrale Nantes Etudes',
		date: 			'2012 - 2016',
		place: 			'Nantes, France'
	}, 
	{
		id: 			5, 	
		type: 			'language', 
		title: 			'English', 
		value: 			100
	},
	{
		id: 			6, 	
		type: 			'language', 
		title: 			'French', 
		value: 			100
	},
		{
		id: 			7, 	
		type: 			'language', 
		title: 			'German', 
		value: 			80
	},
		{
		id: 			8, 	
		type: 			'language', 
		title: 			'Indonesian', 
		value: 			50
	}
]


