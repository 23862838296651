import '../styles/ContactComponent.scss';
import React, {Component} 			from 'react'; 
import {Form, Button} 				from 'react-bootstrap'; 
import { FontAwesomeIcon } 			from '@fortawesome/react-fontawesome';
import {faLinkedinIn} 				from '@fortawesome/free-brands-svg-icons';
import {faEnvelope, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';
import emailjs 						from 'emailjs-com';

class Contact extends Component {

	constructor(props){
		super(props); 

		this.state= {
			name:'', 
			email:'',
			subject:'', 
			message:''
		}

		this.handleChange = this.handleChange.bind(this); 
		this.handleSubmit = this.handleSubmit.bind(this); 
	}

	handleChange(e) {

		const name = e.target.name;
		const value = e.target.value; 

		this.setState({
			[name]: value
		});
	}

	handleSubmit(e) {
		e.preventDefault();

		emailjs.sendForm('gmail', 'template_8m3k8xx', e.target, 'user_WhHeg0FS9OOGVEvVjBePy')
      	.then((result) => {
        	console.log(result.text);
  			alert("Your message has been sent. Thank you.")
      	}, (error) => {
          	console.log(error.text);
          	alert("Your message could not be sent");
      	})
      	.then(() => {
      		this.setState({
				name:'', 
				email:'',
				subject:'', 
				message:''
			});
      	});
	};


	render(){
		return(

		    <div className='page-container contact-page'>

		    	<div className='page-header d-flex flex-wrap'>
		    		<div className='col-12 col-md-3 page-title'>
		    			<h3>Contact</h3>
		    		</div>
		    		<div className='col-12 col-md-9 col-xl-5 page-description'>
		    			<p>I'd be happy to read from you!</p>
		    			<p>
		    				Please connect with me via LinkedIn, email or with the contact form below. 
							<br />Thank you!
						</p>
						<div id="contact-icons">
							<a 
								href="mailto:lysiane.lesaffre@outlook.com"
								target="_blank"
								rel="noopener noreferrer"
								className="contact-link">
								<FontAwesomeIcon className="icon" icon={faEnvelope} />
							</a> 
							<a 
								href="https://www.linkedin.com/in/lysianelesaffre/"
								target="_blank"
								rel="noopener noreferrer"
								className="contact-link">
								<FontAwesomeIcon className="icon" icon={faLinkedinIn} />
							</a> 
						</div>
						<p id='location'>
							<FontAwesomeIcon className="icon" icon={faMapMarkerAlt} />
							<span>France</span>
						</p>
		    		</div>
		    	</div>

				<div className='page-content'>
					<div className='container'>
						<Form onSubmit={this.handleSubmit}>
			    			<Form.Group controlId='name' className="row">
								<Form.Label className="col-12">Name<abbr title="required" aria-label="required">*</abbr></Form.Label>
			    				<Form.Control 
			    					className="col-12" 
			    					type="text" 
			    					name="name"
			    					value={this.state.name}
			    					onChange={this.handleChange}
			    					required />  			
			    			</Form.Group>

			    			<Form.Group controlId='email' className="row">
								<Form.Label className="col-12">Email<abbr title="required" aria-label="required">*</abbr></Form.Label>
			    				<Form.Control 
				    					className="col-12" 
				    					type="email" 
				    					name="email"
				    					value={this.state.email}
				    					onChange={this.handleChange}
				    					required />  	
				    		</Form.Group>

			    			<Form.Group controlId='subject' className="row">
								<Form.Label className="col-12">Subject<abbr title="required" aria-label="required">*</abbr></Form.Label>
			    				<Form.Control 
			    					className="col-12" 
			    					type="text" 
			    					name="subject"
			    					value={this.state.subject}
				    				onChange={this.handleChange}
			    					required />    			
			    			</Form.Group>

			    			<Form.Group controlId='message' className="row">
								<Form.Label className="col-12">Your message<abbr title="required" aria-label="required">*</abbr></Form.Label>
			    				<Form.Control 
			    					className="col-12" 
			    					as="textarea"
			    					name="message"
			    					value={this.state.message}
				    				onChange={this.handleChange}
			    					required />   			
			    			</Form.Group>

			    			<Button className="col-12" type="submit">
			    				Send
			    			</Button>
			    		</Form>
			    	</div>
				</div>
		    </div>
		);
	}
}

 export default Contact; 
