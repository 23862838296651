import '../styles/ProjectsComponent.scss';
import React 				from 'react'; 
import { PROJECTS_DATA } 	from '../shared/projects';
import { TECH_DATA }		from '../shared/tech';
import { FontAwesomeIcon }	from '@fortawesome/react-fontawesome';
import { faFreeCodeCamp} 	from '@fortawesome/free-brands-svg-icons';

function Projects() {

	const TechData = () => {

		const techData = TECH_DATA.map(data => {
			return (
				<div className='col-4 col-sm-2 tech-details' key={data.id}>
					<FontAwesomeIcon className="icon" icon={data.faName} />
					<p>{data.name}</p>
				</div>
			)
		})

		return(
			<div id="tech">
		    	<h4>Technology</h4>
		    	<div className="container d-flex flex-wrap">
		    		{techData}
		    	</div>
		    </div>
		)
	}

	const Projects = () => {

		const projectsData = PROJECTS_DATA.map(data => {
			return (
				<div className='col-12 col-sm-4 project-details' key={data.id}>
					<a href={data.link} target="_blank" rel="noopener noreferrer">
						<img className='img-fluid' src={data.img} alt='project screenshot'/>
						<div className='project-legend'>
							<p>{data.title}</p>	
							<p>{data.subtitle}</p>
						</div>
					</a>
				</div>
			)
		})

		return(
			<div id="projects">
	    		<h4>Projects</h4>
		    	<div className="container d-flex flex-wrap">
		    		{projectsData}
		    	</div>
		    </div>
		)
	};

	return(
	    <div className='page-container' id='projects-page'>
	    	<div className='page-header d-flex flex-wrap'>
	    		<div className='col-12 col-md-3 page-title'>
	    			<h3>Web Projects</h3>
	    		</div>
	    		<div className='col-12 col-md-9 col-xl-5 page-description'>
	    			<FontAwesomeIcon className="icon" icon={faFreeCodeCamp} />
	    			<p>
						Full Stack Certification at   
	    				<a href="https://www.freecodecamp.org/" target="_blank" rel="noopener noreferrer">freeCodeCamp</a>
	    			</p>
	    			<p>Obtained in November 2020</p>
	    			<p>The projects on this page are part of the 30 assignments required to complete the freeCodeCamp Full Stack certification</p>
	    		</div>
	    	</div>

			<div className='page-content'>
		    	<TechData />
		    	<Projects />

		    	<div id="more-projects">
		    		And more to come soon! 
		    	</div>
		    </div>
		</div>
	);
}
 export default Projects; 
